var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"max-width":"550","persistent":""},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[(_vm.showConfirmationDialog)?_c('v-card',[_c('v-card-title',{staticClass:"headline"},[_vm._v("Confirmation")]),_c('v-card-text',[_c('v-card',[_c('v-card-text',[_c('p',[_vm._v(_vm._s(_vm.$t('msg.score_generated_text_1')))]),_c('p',[_vm._v(_vm._s(_vm.$t('msg.score_generated_text_2')))]),_c('v-card-actions',[_c('v-btn',{staticClass:"primary",on:{"click":_vm.destroy}},[_vm._v(_vm._s(_vm.$t('cancel')))]),_c('v-spacer'),_c('v-btn',{staticClass:"primary",on:{"click":_vm.hideConfirmationDialog}},[_vm._v(_vm._s(_vm.$t('confirm')))])],1)],1)],1)],1)],1):_c('v-card',[_c('v-card-title',{staticClass:"headline"},[_vm._v(_vm._s(_vm.$t('msg.edit_customer_info')))]),_c('v-card-text',[_c('ValidationObserver',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var failedRules = ref.failedRules;
var failed = ref.failed;
var passes = ref.passes;
return [_c('form',[_c('v-skeleton-loader',{attrs:{"loading":_vm.loading,"transition":"scale-transition","type":"list-item-two-line"}},[_c('ValidationProvider',{attrs:{"rules":{required: true, min : 1, max : 10, regex:/^[a-zA-Z0-9]+$/ }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var failedRules = ref.failedRules;
return [_c('v-text-field',{staticClass:"requiredSticker",attrs:{"error-messages":_vm.$translatedErrorMessages(failedRules,'applicationNumber'),"label":_vm.$t('lbl.application_id')},model:{value:(_vm.customer.applicationNumber),callback:function ($$v) {_vm.$set(_vm.customer, "applicationNumber", $$v)},expression:"customer.applicationNumber"}})]}}],null,true)})],1),_c('div',[_c('div',{directives:[{name:"show",rawName:"v-show",value:(failed),expression:"failed"}]},[_c('p',{staticStyle:{"color":"red"}},[_vm._v(_vm._s(_vm.$t('msg.failed_action')))])]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.saveError),expression:"saveError"}]},[_c('p',{staticStyle:{"color":"red"}},[_vm._v(_vm._s(_vm.$t('msg.failed_action')))])]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.serverError),expression:"serverError"}]},[_c('p',{staticStyle:{"color":"red"}},[_vm._v(_vm._s(_vm.$t('error.server')))])]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.informationTampering),expression:"informationTampering"}]},[_c('p',{staticStyle:{"color":"red"}},[_vm._v(_vm._s(_vm.$t('error.information_tampering')))])])]),_c('v-card-actions',{attrs:{"align":"right"}},[_c('v-spacer'),_c('v-skeleton-loader',{staticClass:"mr-3",attrs:{"loading":_vm.loading||_vm.sending,"transition":"scale-transition","type":"button"}},[_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.destroy}},[_vm._v(_vm._s(_vm.$t('cancel')))])],1),_c('v-skeleton-loader',{attrs:{"loading":_vm.loading||_vm.sending,"transition":"scale-transition","type":"button"}},[_c('v-btn',{attrs:{"color":"primary","dark":""},on:{"click":function($event){return passes(_vm.saveCustomer)}}},[_vm._v(_vm._s(_vm.$t('save'))+" ")])],1)],1)],1)]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }