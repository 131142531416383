var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-container',{staticClass:"mt-lg-10",attrs:{"fluid":""}},[_c('v-row',{attrs:{"dense":""}},[_c('v-col',[_c('v-card',[_c('v-app-bar',{attrs:{"color":"primary","dark":""}},[_c('v-icon',{staticClass:"mr-3"},[_vm._v("mdi-filter")]),_c('v-toolbar-title',[_vm._v(_vm._s(_vm.$t('msg.filters_title')))])],1),_c('v-container',{attrs:{"fluid":""}},[_c('v-row',{attrs:{"dense":""}},[_vm._l((_vm.filters),function(value,key,idx){return _c('v-col',{key:idx,attrs:{"cols":"12","lg":"3","md":"3"}},[_c('v-skeleton-loader',{attrs:{"loading":_vm.loadingFilterData,"transition":"scale-transition","type":"card-heading"}},[(key === 'scheme')?_c('v-select',{staticClass:"contain-long-inputs",attrs:{"items":value,"label":_vm.$t(("msg." + key + "_label")),"chips":"","deletable-chips":"","dense":"","item-text":"label","item-value":"code","outlined":"","small-chips":""},model:{value:(_vm.filterCriteria[("" + key)]),callback:function ($$v) {_vm.$set(_vm.filterCriteria, ("" + key), $$v)},expression:"filterCriteria[`${key}`]"}}):_c('v-select',{staticClass:"contain-long-inputs",attrs:{"items":value,"label":_vm.$t(("msg." + key + "_label")),"chips":"","deletable-chips":"","dense":"","outlined":"","small-chips":""},model:{value:(_vm.filterCriteria[("" + key)]),callback:function ($$v) {_vm.$set(_vm.filterCriteria, ("" + key), $$v)},expression:"filterCriteria[`${key}`]"}})],1)],1)}),_c('v-col',{staticClass:"col-12 col-lg-3 col-md-3"},[_c('v-skeleton-loader',{attrs:{"loading":_vm.loadingFilterData,"transition":"scale-transition","type":"card-heading"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"min-width":"290px","offset-y":"","transition":"scale-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-text-field',_vm._g({attrs:{"label":_vm.$t('msg.start_date'),"append-icon":"event","clearable":"","dense":"","outlined":"","readonly":""},on:{"change":_vm.startDateChanged},model:{value:(_vm.filterCriteria.startDate),callback:function ($$v) {_vm.$set(_vm.filterCriteria, "startDate", $$v)},expression:"filterCriteria.startDate"}},on))]}}]),model:{value:(_vm.startDate),callback:function ($$v) {_vm.startDate=$$v},expression:"startDate"}},[_c('v-date-picker',{attrs:{"max":_vm.dateEndLimit1},on:{"input":function($event){_vm.startDate = false},"change":_vm.startDateChanged},model:{value:(_vm.filterCriteria.startDate),callback:function ($$v) {_vm.$set(_vm.filterCriteria, "startDate", $$v)},expression:"filterCriteria.startDate"}})],1)],1)],1),_c('v-col',{staticClass:"col-12 col-lg-3 col-md-3"},[_c('v-skeleton-loader',{attrs:{"loading":_vm.loadingFilterData,"transition":"scale-transition","type":"card-heading"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"min-width":"290px","offset-y":"","transition":"scale-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-text-field',_vm._g({attrs:{"label":_vm.$t('msg.end_date'),"append-icon":"event","clearable":"","dense":"","outlined":"","readonly":""},on:{"click:clear":_vm.endDateChanged},model:{value:(_vm.filterCriteria.endDate),callback:function ($$v) {_vm.$set(_vm.filterCriteria, "endDate", $$v)},expression:"filterCriteria.endDate"}},on))]}}]),model:{value:(_vm.endDate),callback:function ($$v) {_vm.endDate=$$v},expression:"endDate"}},[_c('v-date-picker',{attrs:{"min":_vm.dateStartLimit2,"max":_vm.dateEndLimit2},on:{"input":function($event){_vm.endDate = false},"change":_vm.endDateChanged},model:{value:(_vm.filterCriteria.endDate),callback:function ($$v) {_vm.$set(_vm.filterCriteria, "endDate", $$v)},expression:"filterCriteria.endDate"}})],1)],1)],1),_c('v-col',{staticClass:"d-flex justify-lg-end justify-md-end justify-center"},[_c('v-skeleton-loader',{staticClass:"mr-3",attrs:{"loading":_vm.loadingFilterData,"transition":"scale-transition","type":"button"}},[_c('v-btn',{attrs:{"color":"primary","dark":""},on:{"click":_vm.resetFilters}},[_vm._v(_vm._s(_vm.$t('reset')))])],1),_c('v-skeleton-loader',{attrs:{"loading":_vm.loadingFilterData,"transition":"scale-transition","type":"button"}},[_c('v-btn',{attrs:{"color":"primary","dark":""},on:{"click":_vm.filterData}},[_vm._v(_vm._s(_vm.$t('filter')))])],1)],1)],2)],1)],1)],1)],1)],1),_c('v-container',{staticClass:"customersTablePadding",attrs:{"fluid":""}},[_c('v-row',[_c('v-col',[_c('v-card',[_c('v-app-bar',{attrs:{"color":"primary","dark":""}},[_c('v-icon',{staticClass:"mr-3"},[_vm._v("mdi-account-group")]),_c('v-toolbar-title',{staticClass:"responsive-title"},[(_vm.$route.params.userType !== undefined &&
                _vm.$store.state.home.user.products.length > 1)?_c('span',[_vm._v(" "+_vm._s(_vm.$route.params.userType.toUpperCase())+" ")]):_vm._e(),_vm._v(" "+_vm._s(_vm.$t('msg.customers'))+" ")]),_c('v-row',{attrs:{"justify":"end"}},[_c('v-dialog',{attrs:{"max-width":"450","persistent":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"pad-filter-button",attrs:{"color":"primary","dark":""}},on),[_vm._v(_vm._s(_vm.$t('msg.view_applied_filters')))])]}}]),model:{value:(_vm.appliedDialog),callback:function ($$v) {_vm.appliedDialog=$$v},expression:"appliedDialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"headline"},[_vm._v(_vm._s(_vm.$t('msg.filters_applied')))]),_c('v-card-text',[_c('v-simple-table',{scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('tbody',[_vm._l((_vm.filters),function(value,key,idx){return _c('tr',{key:idx},[_c('td',[_vm._v(_vm._s(_vm.$t(("msg." + key + "_label"))))]),_c('td',[_vm._v(":")]),(undefined == _vm.appliedFilters[key])?_c('td',[_vm._v(" "+_vm._s(_vm.$t(("msg." + key + "_all_applied")))+" ")]):(key === 'scheme')?_c('td',[_vm._v(" "+_vm._s(value.filter(function (f) { return f.code === _vm.appliedFilters[key]; })[0].label)+" ")]):_c('td',[_vm._v(" "+_vm._s(value.filter(function (f) { return f.value === _vm.appliedFilters[key]; })[0].text)+" ")])])}),_c('tr',[_c('td',[_vm._v(_vm._s(_vm.$t('msg.start_date')))]),_c('td',[_vm._v(":")]),(_vm.appliedFilters.startDate)?_c('td',[_vm._v(" "+_vm._s(_vm.appliedFilters.startDate)+" ")]):_c('td',[_vm._v(" "+_vm._s(_vm.$t('msg.all_dates'))+" ")])]),_c('tr',[_c('td',[_vm._v(_vm._s(_vm.$t('msg.end_date')))]),_c('td',[_vm._v(":")]),(_vm.appliedFilters.endDate)?_c('td',[_vm._v(" "+_vm._s(_vm.appliedFilters.endDate)+" ")]):_c('td',[_vm._v(" "+_vm._s(_vm.$t('msg.all_dates'))+" ")])])],2)]},proxy:true}])})],1),_c('v-card-actions',[_c('div',{staticClass:"flex-grow-1"}),_c('v-btn',{attrs:{"color":"primary","dark":""},on:{"click":function($event){_vm.appliedDialog = false}}},[_vm._v(" "+_vm._s(_vm.$t('msg.close_label'))+" ")])],1)],1)],1)],1)],1),_c('v-container',{staticClass:"containerStyling",attrs:{"fluid":""}},[_c('v-row',{staticClass:"d-flex justify-center"},_vm._l((_vm.headers),function(header,index){return _c('span',{key:index},[(header.toggle &&
                      (undefined === header.roles || header.roles.some(function (r) { return _vm.$store.state.home.user.roles.includes(r); })))?_c('v-col',[_c('v-checkbox',{attrs:{"label":header.text,"color":"primary"},on:{"change":function($event){return _vm.recordTicks()}},model:{value:(header.selectedCheckbox),callback:function ($$v) {_vm.$set(header, "selectedCheckbox", $$v)},expression:"header.selectedCheckbox"}})],1):_vm._e()],1)}),0)],1),_c('v-card',[_c('v-skeleton-loader',{attrs:{"loading":_vm.tableDataLoading,"transition":"scale-transition","type":"table-tbody"}},[_c('v-card-title',[_c('v-row',[_c('v-col',{attrs:{"md":"7"}}),_c('v-text-field',{attrs:{"hide-details":"","placeholder":"Search","single-line":""},model:{value:(_vm.filterCriteria.searchText),callback:function ($$v) {_vm.$set(_vm.filterCriteria, "searchText", $$v)},expression:"filterCriteria.searchText"}}),_c('div',{staticClass:"pl-3 mt-2"}),_c('v-btn',{staticClass:"ma-2",attrs:{"color":"primary"},on:{"click":_vm.filterData}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v("mdi-magnify")])],1)],1)],1)],1),_c('v-data-table',{directives:[{name:"show",rawName:"v-show",value:(!_vm.tableDataLoading),expression:"!tableDataLoading"}],attrs:{"disable-sort":true,"footer-props":{
                              'items-per-page-options': _vm.pagination.itemPerPageOptions,
                              'show-current-page':true
                            },"headers":_vm.filteredHeaders,"items":_vm.table.tableData,"options":_vm.pagination,"server-items-length":_vm.table.totalLength},on:{"update:options":[function($event){_vm.pagination=$event},_vm.paginate]},scopedSlots:_vm._u([{key:"item.applicationNumber",fn:function(ref){
                            var item = ref.item;
return [(item.applicationNumber)?_c('span',[_vm._v(" "+_vm._s(item.applicationNumber)+" ")]):_c('span',{staticClass:"text-center"},[_c('v-icon',[_vm._v("$vuetify.icons.values.mdiMinus")])],1)]}},{key:"item.businessRegistrationNumber",fn:function(ref){
                            var item = ref.item;
return [(item.businessRegistrationNumber)?_c('span',[_vm._v(" "+_vm._s(item.businessRegistrationNumber)+" ")]):_c('span',{staticClass:"text-center"},[_c('v-icon',[_vm._v("$vuetify.icons.values.mdiMinus")])],1)]}},{key:"item.companyName",fn:function(ref){
                            var item = ref.item;
return [(item.companyName)?_c('span',[_vm._v(" "+_vm._s(item.companyName)+" ")]):_c('span',{staticClass:"text-center"},[_c('v-icon',[_vm._v("$vuetify.icons.values.mdiMinus")])],1)]}},{key:"item.gfi_completion_date",fn:function(ref){
                            var item = ref.item;
return [(item.completionDateString)?_c('span',[_vm._v(" "+_vm._s(item.completionDateString)+" ")]):_c('span',{staticClass:"text-center"},[_c('v-icon',[_vm._v("$vuetify.icons.values.mdiMinus")])],1)]}},{key:"item.details",fn:function(ref){
                            var item = ref.item;
return [(item.status==='FINISHED' && item.gfiScoreGenerationCount<2)?_c('v-icon',{staticClass:"mr-2",attrs:{"color":"primary darken-2","small":""},on:{"click":function($event){return _vm.editItem(item, true)}}},[_vm._v(" mdi-square-edit-outline ")]):_c('v-icon',{staticClass:"mr-2",attrs:{"color":"grey darken-2","small":""}},[_vm._v(" mdi-square-edit-outline ")])]}},{key:"item.scoring",fn:function(ref){
                            var item = ref.item;
return [(item.readyForScoring && !item.scoringTriggeredByOfficer && item.gfiScoreGenerationCount<2)?_c('v-icon',{staticClass:"mr-2",attrs:{"color":"blue darken-2","small":""},on:{"click":function($event){return _vm.generateScore(item)}}},[_vm._v(" mdi-calculator ")]):_vm._e(),((!item.readyForScoring) || (item.gfiScoreGenerationCount>1) || (item.readyForScoring && item.scoringTriggeredByOfficer))?_c('v-icon',{staticClass:"mr-2",attrs:{"color":"grey darken-2","small":""}},[_vm._v(" mdi-calculator ")]):_vm._e()]}},{key:"item.applicationStatus",fn:function(ref){
                            var item = ref.item;
return [_vm._v(" "+_vm._s(item.applicationStatusLabel)+" "),_c('span',[(['ROLE_HQ_OFFICER'].some(function (r) { return _vm.$store.state.home.user.roles.includes(r); }))?_c('v-icon',{staticClass:"mr-2",attrs:{"color":"primary","small":""},on:{"click":function($event){return _vm.editApplicationStatus(item)}}},[_vm._v(" mdi-square-edit-outline ")]):_vm._e()],1)]}},{key:"item.testTakerScoreOverallRating",fn:function(ref){
                            var item = ref.item;
return [(item.completionStatus == 'Report Available')?_c('span',[_vm._v(" "+_vm._s(item.testTakerScoreOverallRating)+" ")]):_c('span',{staticClass:"text-center"},[_c('v-icon',[_vm._v("$vuetify.icons.values.mdiMinus")])],1)]}},{key:"item.gfiRating",fn:function(ref){
                            var item = ref.item;
return [(item.completionStatus == 'Report Available' )?_c('span',[_vm._v(" "+_vm._s(item.gfiRating)+" ")]):_c('span',{staticClass:"text-center"},[_c('v-icon',[_vm._v("$vuetify.icons.values.mdiMinus")])],1)]}},{key:"item.report",fn:function(ref){
                            var item = ref.item;
return [_c('span',{directives:[{name:"show",rawName:"v-show",value:(!_vm.isFinancialAdvisoryOfficer),expression:"!isFinancialAdvisoryOfficer"}]},[(item.completionStatus == 'Report Available' )?_c('span',[(_vm.checkArray(item.id,item.idNumber,'BANK_REPORT'))?_c('span',[_c('v-icon',{attrs:{"color":"blue"},nativeOn:{"click":function($event){return _vm.downloadReport(item.id,item.idNumber,'BANK_REPORT')}}},[_vm._v("mdi-file-download")])],1):_c('span',[_c('v-progress-circular',{attrs:{"size":20,"color":"primary","indeterminate":""}})],1)]):_c('span',[_c('v-icon',[_vm._v("mdi-file-download")])],1)])]}},{key:"item.report1",fn:function(ref){
                            var item = ref.item;
return [_c('span',{directives:[{name:"show",rawName:"v-show",value:(!_vm.isFinancialAdvisoryOfficer),expression:"!isFinancialAdvisoryOfficer"}]},[(item.completionStatus == 'Report Available' )?_c('span',[(_vm.checkArray(item.id,item.idNumber,'BANK_REPORT1'))?_c('span',[_c('v-icon',{attrs:{"color":"blue"},nativeOn:{"click":function($event){return _vm.downloadFINReport(item.id,item.idNumber,'BANK_REPORT1')}}},[_vm._v("mdi-file-download")])],1):_c('span',[_c('v-progress-circular',{attrs:{"size":20,"color":"primary","indeterminate":""}})],1)]):_c('span',[_c('v-icon',[_vm._v("mdi-file-download")])],1)])]}}])}),(_vm.isFinancialAdvisoryOfficer)?_c('div',[_c('v-card',{staticClass:"dashboard-datatable-footer dashboardFooterBottomMargin"},[_c('v-card-actions',{staticClass:"dashboard-datatable-footer-actions"},[_c('p',[_c('v-icon',{staticClass:"px-2",attrs:{"x-small":""}},[_vm._v("mdi-checkbox-blank-circle")]),_vm._v(" "+_vm._s(_vm.$t('msg.f1_text'))+" ")],1),_c('p',[_c('v-icon',{staticClass:"px-2",attrs:{"x-small":""}},[_vm._v("mdi-checkbox-blank-circle")]),_vm._v(" "+_vm._s(_vm.$t('msg.f2_text'))+" ")],1),_c('p',[_c('v-icon',{staticClass:"px-2",attrs:{"x-small":""}},[_vm._v("mdi-checkbox-blank-circle")]),_vm._v(" "+_vm._s(_vm.$t('msg.f3_text'))+" ")],1)])],1)],1):_vm._e()],1)],1)],1)],1),_c('CustomerMaintenance',{ref:"customerMaintenanceWidget"}),_c('UpdateApplicationStatus',{ref:"applicationStatusUpdateWidget"}),_c('ScoreGenerationConfirmationWidget',{ref:"generateScoreWidget"})],1),_c('v-snackbar',{attrs:{"right":'right',"timeout":4000,"top":'top',"color":"success"},model:{value:(_vm.alert),callback:function ($$v) {_vm.alert=$$v},expression:"alert"}},[_vm._v(" "+_vm._s(_vm.message)+" ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }